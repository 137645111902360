.wishlist-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1.5rem;
}

.wishlist-screen {
    background-color: #F0F0F0;
}

@media (max-width : 1100px){
    .wishlist-grid{
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width : 850px){
    .wishlist-grid{
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width : 600px){
    .wishlist-grid{
        grid-template-columns: 1fr;
    }
}