.checkout-card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    background-color: white;
    padding: 10px 8px;
    gap: 1.5rem;
    margin-bottom: 2rem;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px 0 rgba(0, 0, 0, 0.24);
}

.checkout-card * {
    /* border: 1px solid red; */
}

.checkout-card img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin: 0;
}

@media (max-width : 500px) {
    .checkout-card img {
        height: 50px;
        width: auto;
    }
}