.wishlist-card {
   display: flex;
   flex-direction: column;
   align-items: center;
   height: 300px;
   padding: 15px 20px;
   justify-content: space-between;
   background-color: white;
   border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px 0 rgba(0, 0, 0, 0.24);
   position: relative;
}

.bin-icon {
   position: absolute;
   top: 8px;
   right: 5px;
}