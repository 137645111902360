.cart-card {
    display: flex;
    height: 250px;
    align-items: center;
    justify-content: flex-start;
    gap: 1.2rem;
    border: 1px solid;
    background-color: white;
    margin-bottom: 2rem;
    border-radius: 8px;
    border: none;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px 0 rgba(0, 0, 0, 0.24);
    padding: 15px 25px;
}

.cart-card img {
    height: 100%;
    width: auto;
    object-fit: contain;
}

@media (max-width : 700px) {
    .cart-card {
        display: flex;
        flex-direction: column;
        height: auto;
        width: auto;
    }
}