.steps {
    display: flex;
    gap: 3rem;
    justify-content: center;
    margin-bottom: 2rem;
}

.steps .step small {
    font-size: 15px;
    color: #4b5563;
}

.steps .step small:hover{
    cursor: pointer;
}

.bold {
    color: black;
    font-weight: bold;
}

.bold:hover{
    cursor: pointer;
}

.show-address {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
}


.checkout-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.checkout {
    background-color: #F0F0F0;
    padding: 20px 30px;
    margin-top: 1.5rem 0 0 0;
}

@media (max-width : 450px){
    .steps {
        display: none;
    }
}
