* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* border: 1px solid red; */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-decoration: none;
  color: black;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.screen {
  padding: 60px 40px;
  /* background-color: #d1d5db; */
}

@media (max-width : 500px){
  .screen {
    padding: 30px 20px;
  }
}

.image {
  height: 150px;
  width: 220px;
  background-color: grey;
  border-radius: 8px;
}

.flex {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

.underline {
  text-decoration: underline;
}

.button {
  padding: 8px 12px;
  border: 0.3px solid currentColor;
  border-radius: 8px;
  margin-right: 10px;
  /* font-size: 16px; */
  /* border: 0.5px solid; */
  /* border: 0.5px solid black; */
  /*
    padding: 10px 12px;
    font-size: 16px;
    width: 100%;
    border-radius: 8px;
    border: 0.5px solid;
    background-color: #111827;
    color: white;
  */
}

.center-form {
  height: 70vh;
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form {
  padding: 2.5rem 7rem;
  border: 1px solid #d1d1d1;
  background-color: #d1d5db;
  border-radius: 12px;
  width: 30rem;
}

.form input {
  padding: 10px 12px;
  font-size: 16px;
  text-align: left;
  border-radius: 4px;
  border: 0.5px solid;
  width: 100%;
}

.center{
  text-align: center;
  width: 100%;
}

.blue {
  color: #1d4ed8;
}

/* checkout screen css */
.steps {
  color: red;
}

.disabled:hover{
  cursor: not-allowed;
}

form input {
  padding: 8px 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 0.5px solid;
}

.width-300 {
  width: 500px;
}

.nothing {
  width: 300px;
  height: auto;
  object-fit: contain;
}

@media (max-width : 600px) {
  .width-300 {
    width: 100%;
  }
}