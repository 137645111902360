.products-listing {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 2rem;
    column-gap: 2rem;
    background-color: #f1f1f1;
    padding: 30px 25px;
}

.sidebar {
    position: fixed;
    left: 0;
    width: 300px;
    height: 100vh;
    z-index: 999;
    padding: 10px 50px 10px 20px;
    overflow-y: scroll;
  }
  
  .sidebar ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .sidebar li {
    padding: 10px;
  }
  
  .products-listing {
    margin-left: 15rem; /* Account for the width of the sidebar */
  }

  .clear {
    text-decoration: underline;
  }

  .products-screen h2 {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .clear:hover {
    cursor: pointer;
  }

  @media (max-width : 1300px){
    .products-listing {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width : 1000px){
    .products-listing {
      grid-template-columns: 1fr 1fr;
      margin-bottom: 2rem;
      margin-left: 10px;
    }

    .sidebar {
      display: none;
      width: 0;
      height: 0;
    }
  }

  @media (max-width : 800px){
    .products-listing {
      grid-template-columns: 1fr;
    }

    .products-screen h3 {
      margin-left: none;
    }
  }
