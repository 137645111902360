.address-profile-card {
    width: 250px;
    padding: 10px 12px;
    border: 2px solid #f3f4f6;
    background-color: #f3f4f6;
    border-radius: 8px;
    position: relative;
}

.tag {
    position: absolute;
    top: 3px;
    right: 3px;
    font-size: 10px;
    padding: 5px;
    background-color: #10b981;
    color: white;
    border-radius: 8px;
}