.product-card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    position: relative;
    height: auto;
    width: 300px;
    height: auto;
    padding : 30px;
    border: 1px solid;
    background-color: white;
    border-radius: 8px;
    border: none;
    /* border: 0.5px solid currentColor; */
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px 0 rgba(0, 0, 0, 0.24);

}

.product-card h3:hover {
    cursor: pointer;
    text-decoration: underline;
}

.image-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.product-img {
    height: 150px;
    width: 200px;
    object-fit: contain;
    transition: 0.3s ease-in-out;
}

.product-img:hover{
    transform: scale(1.050);
    cursor: pointer;
}

.wishlist {
    height: 30px;
    position: absolute;
    right: 9px;
    top: 10px;
}