.profile-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: start;
    gap: 1.5rem;
}

.options-list {
    list-style: none;
    text-align: left;
}

.selected {
    background-color:#111827;
}

.options-list li {
    padding: 10px 12px;
    margin-bottom: 2rem;
    border-radius: 4px;
    border: 1px solid currentColor;
    background-color: #d1d5db;
    width: 70%;
}

.profile-component {
    text-align: left;
}

.address-flex {
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    flex-wrap: wrap;
}

@media (max-width : 750px){
    .options-list li {
        width: 100%;
    }
}

@media (max-width : 600px){
    .profile-grid{
        display: block;
    }
    .options-list {
        display: flex;
    }

    .options-list li {
        padding: 0;
        background-color: white;
        border: none;
        text-decoration: underline;
        font-weight: bold;
        margin-right: 1.5rem;
    }
}