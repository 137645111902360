.navigation-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 40px;
    position: relative;
    z-index: 1;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
    background-color: white;
    /* flex-wrap: wrap; */
}

.logo {
    font-size:x-large;
}

.search {
    padding: 5px 16px;
    border: solid 2px black;
    font-size: 16px;
    width: 20vw;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.search input {
    border: none;
    width: 100%;
    margin-left: 5px;
    font-size: 16px;
}

.options {
    display: flex;
    justify-content: space-between;
    width: 25vw;
    align-items: center;
}

.options:hover{
    cursor: pointer;
}

.explore-text {
    font-size: 18px;
    font-weight: 600;
}

.notification {
   position: absolute;
   top: -10px;
   right: 4;
   color: #dc2626;
   font-size: 700;
}

.link {
    position: relative;
}

.profile-link {
    background-color: #e5e7eb;
    padding: 5px 10px;
    border: none;
    border-radius: 8px;
}

@media (max-width : 900px) {
    .options {
        width: 40vw;
    }
}

@media (max-width : 600px){
    .search {
        /* display: none; */
        width: 80%;
    }

    .navigation-bar {
        display: flex;
        flex-direction: column;
        gap: 1.1rem;
    }

    .options {
        width: 70vw;
    }

}


