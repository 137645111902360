.screen h1 {
    text-align: center;
}

.categories-flex {
    padding: 40px 0px;
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media (max-width : 1300px){
    .categories-flex {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width : 1000px){
    .categories-flex {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width : 700px){
    .categories-flex {
        grid-template-columns: 1fr;
    }
}

.home-screen {
    background-color: #F0F0F0;
}